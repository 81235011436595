import { PRODUCT_CATEGORY } from '../../constants/categories';
import { CategoryService } from '../../services/categories.service';
import { setError } from '../common/common.actions';

export const FETCHING_CATEGORIES = 'FETCHING_CATEGORIES';
export const FETCH_CATEGORIES = 'FETCH_CATEGORIES';
export const FETCH_CATEGORIE_CHILDREN = 'FETCH_CATEGORIE_CHILDREN';
export const PUSH_CATEGORIES = 'PUSH_CATEGORIES';
export const CREATE_CATEGORY = 'CREATE_CATEGORY';
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY';
export const SELECTED_CATEGORY = 'SELECTED_CATEGORY';
export const DELETE_CATEGORY = 'DELETE_CATEGORY';
export const DELETE_CATEGORIES = 'DELETE_CATEGORIES';
export const SET_CATEGORY_QUERY_PARAM = 'SET_CATEGORY_QUERY_PARAM';

export const FETCH_CATEGORY_TYPES = 'FETCH_CATEGORY_TYPES';
export const FETCH_CATEGORY_PARENTS = 'FETCH_CATEGORY_PARENTS';

export const FETCH_CATEGOTY_VARIANTS = 'FETCH_CATEGOTY_VARIANTS';

export const fetchCategories = params => async dispatch => {
  dispatch({ type: FETCHING_CATEGORIES, payload: true });
  try {
    const res = await CategoryService.getCategories(params);

    dispatch({
      type: params?.page > 1 ? PUSH_CATEGORIES : FETCH_CATEGORIES,
      payload: {
        data: res.data.data,
        dataLength: res.data.meta?.total || 0,
        dataTo: res.data.meta?.to || 0,
      },
    });
  } catch (error) {
    dispatch(setError(error));
  } finally {
    dispatch({ type: FETCHING_CATEGORIES, payload: false });
  }
};

export const getSearchedCategories = () => async () => {
  const res = await CategoryService.getCategories({
   // 'filter[is_search]': true,
    without_pagination: 1,
    'filter[show_arm]': true,
  });
  return res.data;
};

export const getCategorieChildren = id => async () => {
  const res = await CategoryService.getCategorieChildren(id);
  return res.data;
};

export const getCategorySiblings = id => async () => {
  const res = await CategoryService.getCategorySiblings(id);
  return res.data;
};

export const searchCategories = search => async () => {
  const res = await CategoryService.getCategories({
    'filter[search]': search,
    'filter[category_type_id]': PRODUCT_CATEGORY,
  });

  return res.data;
};

export const createCategory = category => async dispatch => {
  const res = await CategoryService.createCategory(category);

  await dispatch({ type: CREATE_CATEGORY, payload: res.data.data });
  return res.data.message;
};

export const updateCategory = category => async dispatch => {
  const res = await CategoryService.updateCategory(category);

  await dispatch({ type: UPDATE_CATEGORY, payload: res.data.data });
  return res.data.message;
};

export const selectCategory = category => async dispatch => {
  const res = await CategoryService.selectCategory(category);
  await dispatch({
    type: SELECTED_CATEGORY,
    payload: res.data.data ? res.data.data : '',
  });
  return res.data;
};

export const deleteCategory = category => async dispatch => {
  const res = await CategoryService.deleteCategory(category.id);
  await dispatch({ type: DELETE_CATEGORY, payload: category.id });
  return res.data.message;
};

export const deleteCategories = ids => async dispatch => {
  const res = await CategoryService.deleteCategories(ids);

  await dispatch({ type: DELETE_CATEGORIES, payload: ids });
  return res.data.message;
};

export const fetchCategoryTypes = () => async dispatch => {
  const res = await CategoryService.fetchCategoryTypes();
  dispatch({ type: FETCH_CATEGORY_TYPES, payload: res.data });
};

export const fetchCategoryParents = () => async dispatch => {
  const params = {
    without_pagination: 1,
    'filter[parent_id]': null,
  };
  const res = await CategoryService.fetchCategoryParents(params);
  dispatch({ type: FETCH_CATEGORY_PARENTS, payload: res.data.data });
};

export const fetchCategoryVariants = id => async () => {
  const res = await CategoryService.getCategoryVariants(id);
  return res;
};

export const createCategoryVariants = form => async () => {
  const res = await CategoryService.createCategoryVariants(form);
  return res;
};
